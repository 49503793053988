<template>
  <section class="speciality-area">
    <div style="display: flex;justify-content: space-around;">
        <div style="width: 200px;" class="left-side-container mr-2"
        >
          <p class="blink mb-5"> <a href="http://editorial.fdrpjournals.org/login?journal=2" style="color: #fff;">Submit
              Research Paper</a> </p>
          <div class="shadow-effect mb-5">
            <h6>Downloads :</h6>
            <p style="cursor: pointer;"
              @click="getManuScript('https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/manuscripttemplate/IJSREAT-Manuscript-Template.docx')">

              Manuscript Template
            </p>
            <p style="cursor: pointer;"
              @click="getCopyRightForm('https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/copyrightform/IJSREAT_Registration+%26Copyright-Form.pdf')">
              Copyright Form
            </p>
          </div>
          <div class="shadow-effect mb-5">
            <img src="../../../assets/Images/isn.jpg" height="90" width="200" />
          </div>
          <div class="shadow-effect mb-5">
            <img src="../../../assets/Images/cc.jpg" height="90" width="180" />
            <p>All research Article published on this website are licensed under Creative Commons Attribution-ShareAlike
              4.0
              International License, and all rights belong to their respective authors/researchers.
            </p>
          </div>
          <div>
            <IndexingSideDesign />
          </div>
        </div>
        <div class="container" style="max-width: 1000px !important; margin: 0">
          <div class="row">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-2">
                <div class="archive-data-container" v-if="!listVisible && !paperPage">
                  <div class="" v-for="year in years" :key="year">
                    <h6>{{ year }}</h6>
                    <div class="row" v-for="(data, index) in archives[year]" :key="data.index">
                      <div class="col-lg-3 databox m-1 issueButton" v-for="issue in data.slice().sort()"
                        :key="issue.index" @click="viewIssueData(year, index, issue)">
                        <p>Volume {{ index }} Issue {{ issue }}</p>
                      </div>

                    </div>
                  </div>

                </div>

              </div>

              <div class="paperlist-container " v-if="listVisible && !paperPage">
                <div class="row paperBox paperHeading mb-4 p-2 ">
                  <h5>{{ cYear }} | Volume {{ cVolume }} - Issue {{ cIssue }}</h5>
                </div>
                <div class="row paperBox mb-3" v-for="paper in papersList" :key="paper.paper_uniqueid">
                  <div class="col-9 p-3">
                    <h4>{{ paper.paper_title }}</h4>
                    <p><b>Author:</b> {{ paper.paper_author }}</p>
                    <p><b>Article Type:</b> {{ paper.paper_articletype }}</p>
                    <p><b>Pages:</b> {{ paper.paper_pages }}</p>
                  </div>
                  <div class="col-3 p-3">
                    <b-button class="col-8 mb-2" variant="outline-primary" size="sm"
                      @click="viewPaperDetails(paper.paper_id)">Paper Details</b-button>
                    <b-button class="col-8 mb-2" variant="outline-primary" size="sm"
                      @click="downloadPDF(paper.paper_url)">Download PDF</b-button>
                    <b-button class="col-8" variant="outline-primary" size="sm"
                      @click="googleSearch('https://www.google.com/search?q=' + paper.paper_title)">Google</b-button>
                  </div>
                </div>
              </div>
              <div class="paperdetails-container" v-if="paperPage">
                <div class="row paperBox mb-4 p-3">
                  <div class="header-container row justify-content-between rowSize">
                    <h4 class="col-10 pr-2">{{ paperDetails.paper_title }}</h4>
                    <div class="col-2">
                      <b-button variant="outline-primary" @click="downloadPDF(paperDetails.paper_url)">Download
                        PDF</b-button>
                    </div>
                  </div>
                  <div class="details-container mt-4 p-3">
                    <!-- <div class="author-details pb-3"> -->
                    <p class="pb-3"><b>Author(s) : </b>{{ paperDetails.paper_author }}</p>
                    <!-- </div> -->
                    <!-- <div class="submit-details pb-3"> -->
                    <p class="pb-3"><b>Submission-Year,Month : </b>{{ paperDetails.year }}, {{ paperDetails.month }}</p>
                    <!-- </div> -->
                    <!-- <div class="author-details pb-3"> -->
                    <p class="pb-3"><b>Pages : </b>{{ paperDetails.paper_pages }}</p>
                    <!-- </div> -->
                    <!-- <div class="author-details pb-3"> -->
                    <p class="pb-3"><b>Article Type : </b>{{ paperDetails.paper_articletype }}</p>
                    <!-- </div> -->
                    <!-- <div class="author-details">  -->
                    <p class="pb-3"><b>DOI : </b>{{ paperDetails.paper_doi }}</p>
                    <!-- </div> -->
                  </div>
                </div>
                <div class="row p-3 paperBox">
                  <h4 class="mb-2">Abstract</h4>
                  <p class="abstract-details">
                    {{ paperDetails.paper_abstract }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 200px;" class="right-side-container ml-2"
        >
          <div class="mb-5">
            <router-link to="/editorial-board">
              <p class="blink">Join As A reviewer</p>
            </router-link>
          </div>
          <div class="shadow-effect mb-5">
            <img src="../../../assets/Images/tur.jpg" height="90" width="180" />
            <p>Plagiarism is checked by the leading plagiarism checker</p>
          </div>
          <div class="shadow-effect mb-5">
            <img src="../../../assets/Images/doi.jpg" height="90" width="180" />
            <p>CrossRef DOI is assigned to research Article published in our journal.
              IJIRE DOI prefix is10.59256/ijire
            </p>
          </div>
          <div class="shadow-effect mb-5">
            <h4>For Authors</h4>
            <router-link to="topics">
              <p>Topics</p>
            </router-link>
            <router-link to="call-for-papers">
              <p>Call For Papers</p>
            </router-link>
            <router-link to="instruction-for-author">
              <p>Instruction For Authors</p>
            </router-link>
            <p><a href="http://editorial.fdrpjournals.org/login?journal=2" style="color:rgb(100, 117, 137)">Manuscript
                Submission</a></p>
            <router-link to="guidance-for-ems">
              <p>Guidance For EMS</p>
            </router-link>
            <router-link to="article-processing-charges">
              <p>Article Processing Charges</p>
            </router-link>
            <router-link to="downloads">
              <p>Downloads</p>
            </router-link>
            <router-link to="paper-status">
              <p>Paper Status</p>
            </router-link>
          </div>
          <div class="shadow-effect">
            <h4>Policies</h4>
            <router-link to="ethics">
              <p>Ethics And Policies</p>
            </router-link>
            <router-link to="peer-review-policy">
              <p>Peer Review Policy</p>
            </router-link>
            <router-link to="publication-ethics-policy">
              <p>Publication Ethics Policy</p>
            </router-link>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import IndexingSideDesign from '@/components/common/IndexingSideDesign.vue'

export default {
  components: {
    IndexingSideDesign
  },
  data: function () {
    return {
      archives: {},
      years: [],
      papersList: [],
      listVisible: false,
      paperPage: false,
      cYear: "",
      cVolume: "",
      cIssue: "",
      paperDetails: {},
    };
  },
  methods: {
    getManuScript: function (getUrl) {
      window.open(getUrl, "_blank");
    },
    getCopyRightForm: function (getForm) {
      window.open(getForm, "_blank");
    },
    getArchivesData: function () {
      this.api.getDataModule("archives/3/getarchives", "journals/v1/").then(
        (res) => {
          console.log(res.data);
          this.archives = res.data.archives;
          this.sortData();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getPapersList: function (year, volume, issue) {
      this.api
        .getDataModulePagination(
          "archives/3/getpaperslist",
          "journals/v1/",
          "year=" + year + "&volume=" + volume + "&issue=" + issue
        )
        .then(
          (res) => {
            this.papersList = res.data.papersList;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    getPaperDetails: function (paperid) {
      this.api
        .getDataModulePagination(
          "archives/3/getpaperdetails",
          "journals/v1/",
          "paperid=" + paperid
        )
        .then(
          (res) => {
            this.paperDetails = res.data.paperdetails;
          },
          (err) => {
            console.log(err);
          }
        );
    },
    sortedIssues: function (val) {
      return val.sort().reverse();
    },
    sortData: function () {
      this.years = Object.keys(this.archives).sort().reverse();
    },
    viewIssueData: function (year, volume, issue) {
      this.getPapersList(year, volume, issue);
      this.cYear = year;
      this.cVolume = volume;
      this.cIssue = issue;
      this.listVisible = true;
      this.$router.push({
        path: "archives",
        query: { year: year, volume: volume, issue: issue },
      });
    },
    viewPaperDetails: function (paperid) {
      this.getPaperDetails(paperid);
      this.paperPage = true;
      this.$router.push({ path: "archives", query: { paperid: paperid } });
    },
    downloadPDF: function (paperURL) {
      window.open(paperURL, "_blank");
    },
    googleSearch(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    $route: function () {
      console.log("here");
      if (
        !this.paperPage &&
        this.listVisible &&
        this.cYear == this.$route.query.year &&
        this.cVolume == this.$route.query.volume &&
        this.cIssue == this.$route.query.issue
      ) {
        this.listVisible = true;
      } else {
        this.listVisible = false;
      }

      if ("paperid" in this.$route.query) {
        this.viewPaperDetails(this.$route.query.paperid);
      } else {
        this.paperPage = false;
        this.getArchivesData();
      }
    },
  },
  mounted: function () {
    if ("paperid" in this.$route.query) {
      this.viewPaperDetails(this.$route.query.paperid);
    } else {
      this.listVisible = false;
      this.paperPage = false;
      this.getArchivesData();
      console.log("here");
    }
  },
};
</script>

<style scoped>
.rowSize {
  width: 97%;
}

.issueButton {
  cursor: pointer;
}

.box {
  background: #0000ff;
  border-radius: 8px;
}

.box p {
  color: #fff;

  text-align: center;
  padding: 6px;
}

.btn-outline-primary {
  color: #D1A614;
  border-color: #D1A614;
}

.btn-outline-primary:hover { 
  color: #fff;
  border-color: #D1A614;
  background: #D1A614;
}

.databox {
  background-color: #D1A614;
  border-radius: 5px;
  cursor: pointer;
}

.databox p {
  color: #fff;
  padding: 10px;
}

.paperBox {
  /* border: 2px solid #EEEDE7; */

  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.paperHeading {
  display: flex;
  justify-content: center;
}

p {
  margin-bottom: 5px;
  text-align: left;
}

h5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

h6 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.abstract-details {
  white-space: pre-wrap;
}

@media screen and (max-width: 991px) {
  .btn-sm {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 512px) {
  .btn-sm {
    font-size: 10px !important;
  }
}

.inr-content {
  color: #D1A614;
  font-weight: bold;
} 

.shadow-effect {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 8px;
  border-radius: 5px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 0.5s infinite;
  background-color: #D1A614;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  padding: 5px;
}

.editorialBox {
  /* border: 2px solid #EEEDE7; */
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 85%;
}
</style>
