var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"services-area"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-md-6 mx-auto wow animate__animated animate__slideInUp"},[_c('div',{staticClass:"service-box"},[_vm._m(1),_c('div',{staticClass:"service-text"},[_c('router-link',{attrs:{"to":"/instruction-for-author"}},[_c('h1',[_vm._v("Instruction of Authors")])])],1)])]),_c('div',{staticClass:"col-xl-4 col-md-6 mx-auto wow animate__animated animate__slideInUp"},[_c('div',{staticClass:"service-box"},[_vm._m(2),_c('div',{staticClass:"service-text"},[_c('router-link',{attrs:{"to":"/article-processing-charges"}},[_c('h1',[_vm._v("Article Processing Charges")])])],1)])]),_c('div',{staticClass:"col-xl-4 col-md-6 mx-auto wow animate__animated animate__slideInUp"},[_c('div',{staticClass:"service-box"},[_vm._m(3),_c('div',{staticClass:"service-text"},[_c('router-link',{attrs:{"to":"/guidance-for-ems"}},[_c('h1',[_vm._v("Guidance for Editorial Management System")])])],1)])]),_c('div',{staticClass:"col-xl-4 col-md-6 mx-auto wow animate__animated animate__slideInUp"},[_c('div',{staticClass:"service-box"},[_vm._m(4),_c('div',{staticClass:"service-text"},[_c('router-link',{attrs:{"to":"/archives"}},[_c('h1',[_vm._v("Archives")])]),_c('br')],1)])]),_c('div',{staticClass:"col-xl-4 col-md-6 mx-auto wow animate__animated animate__slideInUp"},[_c('div',{staticClass:"service-box"},[_vm._m(5),_c('div',{staticClass:"service-text"},[_c('router-link',{attrs:{"to":"/paper-status"}},[_c('h1',[_vm._v("Article Status")])])],1)])]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-heading"},[_c('h1',{staticClass:"authors"},[_vm._v("For Authors")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-image"},[_c('img',{attrs:{"src":require("@/assets/Images/authorOne.png"),"height":"53","width":"53"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-image"},[_c('img',{attrs:{"src":require("@/assets/Images/authorTwo.png"),"height":"53","width":"53"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-image"},[_c('img',{attrs:{"src":require("@/assets/Images/authorThree.png"),"height":"53","width":"53"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-image"},[_c('img',{attrs:{"src":require("@/assets/Images/authorFour.png"),"height":"53","width":"53"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-image"},[_c('img',{attrs:{"src":require("@/assets/Images/authorFive.png"),"height":"53","width":"53"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-4 col-md-6 mx-auto wow animate__animated animate__slideInUp"},[_c('div',{staticClass:"service-box"},[_c('div',{staticClass:"service-image"},[_c('img',{attrs:{"src":require("@/assets/Images/authorSix.png"),"height":"53","width":"53"}})]),_c('div',{staticClass:"service-text"},[_c('a',{attrs:{"href":"http://editorial.fdrpjournals.org/login?journal=2"}},[_c('h1',[_vm._v("Online Article Submission")])])])])])
}]

export { render, staticRenderFns }