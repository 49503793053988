/* eslint-disable */
export default {
    papersList: [{
            id: '1',
            title: "Tube Wall Plasticity Behaviour and Strengthening o f Hollow Sectional Tubes",
            author: "M. S. Anagha, Anjal Santhosh",
            submissionYearMonth: "",
            pages: "01-08",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_01_01-08.pdf",
            show: false,
        },
        {
            id: '2',
            title: "Flood Prediction and Susceptibility Mapping of Chalakudy Tehsil Using ARCGIS",
            author: "Ansaf Rasheed, Adhitya M S, Georgy C S, Goury Lakshmi",
            submissionYearMonth: "",
            pages: "09-29",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_02_09-29.pdf",
            show: false,
        },
        {
            id: '3',
            title: "Greywater Treatment Technique Using Fruit Peels",
            author: "Arya. C, Gopu Surjith, Hana P Basheer, Indrajith.J. S, Dr Anjali P Sasidharan",
            submissionYearMonth: "",
            pages: "30-34",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_03_30-34.pdf",
            show: false,
        },
        {
            id: '4',
            title: "Development of Optimum Lightweight Cold-Formed Steel Composite Built-Up Beams",
            author: "Fathima R.M, Nisha Varghese",
            submissionYearMonth: "",
            pages: "35-43",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_04_35-43.pdf",
            show: false,
        },
        {
            id: '5',
            title: "Flexural Behaviour of Hybrid Double-Skin Tubular Beams Having Perfobond Hoop Shear Connectors",
            author: "Jenitta C S, Ardra P Nair",
            submissionYearMonth: "",
            pages: "44-50",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_05_44-50.pdf",
            show: false,
        },
        {
            id: '6',
            title: "Study on Concrete Filled Steel Tube Columns Reinforced with Diagonal Stiffener",
            author: "Muhammed Suhail A S, Kiran Babu A R",
            submissionYearMonth: "",
            pages: "51-54",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_06_51-54.pdf",
            show: false,
        },
        {
            id: '7',
            title: "Seismic Analysis of an Optimised Energy Dissipating Shear Key",
            author: "Nizwin K S, Anju Mary Ealias",
            submissionYearMonth: "",
            pages: "55-62",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_07_55-62.pdf",
            show: false,
        },
        {
            id: '8',
            title: "Experimental and Analytical Investigation on Strengthening of Cutout in a Composite Slab",
            author: "Prince Kesav, Dr Abhilasha P.S.",
            submissionYearMonth: "",
            pages: "63-70",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_08_63-70.pdf",
            show: false,
        },
        {
            id: '9',
            title: "Overtaking Conflict Analysis and Risk Modeling for Undivided Two-Lane Road Section",
            author: "Akshaya V Kumar, Dr. Sheela Alex",
            submissionYearMonth: "",
            pages: "71-78",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_09_71-78.pdf",
            show: false,
        },
        {
            id: '10',
            title: "Study of The Effect of Fiber Type and Volume Variation on High Performance Concrete",
            author: "Ashrin Saira Paul, Anju Paul, Dr. Elson John",
            submissionYearMonth: "",
            pages: "79-83",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_10_79-83.pdf",
            show: false,
        },
        {
            id: '11',
            title: "Water Analysis and Forward Osmosis",
            author: "Shereena N.P , Dr. Noel Jacob Kaleekkal, Fouad Ebn Muhammad Abdulla ",
            submissionYearMonth: "",
            pages: "84-95",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_11_84-95.pdf",
            show: false,
        },
        {
            id: '12',
            title: "Axial Performance of GFRP Wrapped Partially Encased Composite Column with Corrugated Web",
            author: "Sijin Varghese, Dr Abhilasha P. S",
            submissionYearMonth: "",
            pages: "96-101",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_12_96-101.pdf",
            show: false,
        },
        {
            id: '13',
            title: "Axial and Bending Performance of Spiral Stiffened Thin Walled CFDST Column",
            author: "K.S. Siya, E. K. Amritha",
            submissionYearMonth: "",
            pages: "102-109",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_13_102-109.pdf",
            show: false,
        },
        {
            id: '14',
            title: "Efficacy of Activated Carbon in Removing Sulphate and Chloride from Sea Water",
            author: "Navaz K A, Rudhira M R, Saranya V M, Sneha M S, Dr Anjali P Sasidharan",
            submissionYearMonth: "",
            pages: "110-114",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_14_110-114.pdf",
            show: false,
        },
        {
            id: '15',
            title: "Strengthening of fibre reinforced concrete columns using high strength concrete filled steel tubes",
            author: "Sreelakshmi Saji, Nisha Varghese",
            submissionYearMonth: "",
            pages: "115-121",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_15_115-121.pdf",
            show: false,
        },
        {
            id: '16',
            title: "Structural Analysis and Design of Tall Building and Validation Using IS 16700 (2023)",
            author: "Sreerag C S , Sreelakshmi C , Sidharth P , Jofna Joy , Ajayakumar V T",
            submissionYearMonth: "",
            pages: "122-127",
            articleType: "Original Article",
            doi: ".",
            paperUrl: "https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/archives/6dbf0abf-1155-41c6-9fed-c2692a45e33d/REACT'24_IJSREAT_V4I3_16_122-127.pdf",
            show: false,
        },
    ],
}
